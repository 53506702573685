import * as React from "react"
import Navbar from "../components/ui/navbar"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import Section from "../components/common/Section"
import CookieAccordion from "../components/Cookie/CookieAccordion"
import { graphql } from "gatsby"

const CookiePolicyPagePanel = styled.div`
  h3 {
    font-family: CF Asty Pro;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;

    margin-top: 40px;
    margin-bottom: 16px;
  }
`
const CookiePolicy = props => {
  return (
    <Layout location={props.location}>
      <Seo title="Category Page"></Seo>
      <Section className="intro-section">
        <Navbar type="second" />
        <div
          className="container"
          style={{ paddingTop: "80px", paddingBottom: "80px" }}
        >
          <h1 style={{ color: "white" }}>Cookies Policy</h1>
        </div>
      </Section>
      <Section className="activity-section">
        <CookiePolicyPagePanel className="container">
          <div className="row mt-2 mb-2">
            <h3>Πολιτική για τα cookies</h3>
            <span className="w-100">
              Στο αρχείο, χρησιμοποιούμε cookies για την εξασφάλιση της
              λειτουργικότητας, την διατήρηση των προτιμήσεων σου, την ανώνυμη
              στατιστική παρακολούθηση της επισκεψιμότας και την προβολή
              σχετικού περιεχομένου στα μέσα δικτύωσης.
            </span>
          </div>
          <div className="row my-4">
            <CookieAccordion className="no-border" />
          </div>
        </CookiePolicyPagePanel>
      </Section>
    </Layout>
  )
}

export default CookiePolicy

export const query = graphql`
  query CookieQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
